import store from "@/store";
import { ref, computed } from "@vue/composition-api";
import { DashboardActionTypes, DashboardGetters } from "@/store/dashboard";

export default function useDashboard() {
  const currentPage = new ref(1);
  const currentSamplePage = new ref(1);
  const newsList = computed(() => store.getters[DashboardGetters.GET_NEWS_LIST]);

  const blogsList = computed(() => store.getters[DashboardGetters.GET_BLOGS_LIST]);

  const listingCounts = computed(() => store.getters[DashboardGetters.GET_LISTING_COUNTS]);

  const inventoryCounts = computed(() => store.getters[DashboardGetters.GET_INVENTORY_COUNTS]);

  const sampleCounts = computed(() => store.getters[DashboardGetters.GET_SAMPLE_COUNTS]);

  const getStoreLoading = computed(() => store.getters[DashboardGetters.GET_STORE_LOADING]);

  const onLoadDashboard = async () => {
    await store.dispatch(DashboardActionTypes.LOAD_DASHBOARD);
  };

  return {
    newsList,
    blogsList,
    listingCounts,
    inventoryCounts,
    sampleCounts,
    getStoreLoading,
    onLoadDashboard,
    currentPage,
    currentSamplePage
  };
}
