<template>
  <b-card
      :title="header"
      no-body
  >
    <b-carousel
        id="carousel-1"
        controls
        indicators
        style="text-shadow: 0px 0px 30px #000"
    >
      <template v-if="data && data.length > 0">
        <b-carousel-slide v-for="item in data" :value="item" :key="item.link"
            :caption-html="'<a :href=item.link> <span class=\'text-white\'>' + item.headline + '</span></a>'"
            class="card-img-overlay"
            :text="item.body"
        >
          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
          <template #img>
            <img
                class="d-block  w-100"
                :width="imageWidth"
                :height="imageHeight"
                :src="item.image"
                alt="image slot"
            >
          </template>
        </b-carousel-slide>

      </template>
    </b-carousel>
  </b-card>
</template>

<script>
import { BCarousel, BCarouselSlide, BCardText, BCard, BLink } from 'bootstrap-vue'
export default {
  name: "DashboardCarousel",
  components: {
    BCard,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BLink
  },
  props: {
    data: Array,
    header: String,
    imageWidth: String,
    imageHeight: String
  },
  setup() {

  },
  mounted() {
    console.log(this.imageWidth)
  }
}
</script>

<style scoped>
.image-size {
  width: 800px;
  height: 600px;
}
</style>