<template>
  <div>
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-button-group></b-button-group>
        <b-button-group class="mr-2 mb-1" vertical>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="onLoadDashboard">
            <b-spinner v-if="getStoreLoading" small style="margin-bottom: 3px"></b-spinner>
            Refresh
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getStoreLoading" rounded="sm" opacity="0.8">
      <b-row class="match-height">
        <!-- sample counts Table Card -->
        <b-col lg="6" max-height="400px">
          <b-card v-if="sampleCounts" class="card-company-table" title="Sample Counts">
            <b-pagination v-model="currentSamplePage" :total-rows="sampleCounts.length" per-page="10" aria-controls="sample-table"></b-pagination>

            <b-table id="sample-table" :items="sampleCounts" :current-page="currentSamplePage" responsive :fields="sampleCountsFields" class="mb-0" small striped per-page="10">
              <!-- facility information -->
              <template #cell(facilityName)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.facilityName }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.facilityLicense }}
                    </div>
                    <div class="font-small-2 text-muted">DBA: {{ data.item.facilityDba }}</div>
                  </div>
                </div>
              </template>
              <template #cell(requestedTotal)="data">
                <b-link @click="onSampleCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
              <template #cell(shippedTotal)="data">
                <b-link @click="onSampleCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
              <template #cell(receivedTotal)="data">
                <b-link @click="onSampleCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
              <template #cell(reviewedTotal)="data">
                <b-link @click="onSampleCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <!-- Inventory counts Table Card -->
        <b-col lg="6">
          <b-card v-if="inventoryCounts" title="Inventory Counts" class="card-company-table" max-height="400px">
            <b-pagination v-model="currentPage" :total-rows="inventoryCounts.length" per-page="10" aria-controls="inventory-table"></b-pagination>
            <b-table :items="inventoryCounts" responsive :fields="inventoryCountsFields" class="mb-0" small striped per-page="10" id="inventory-table" :current-page="currentPage">
              <!-- facility information -->
              <template #cell(facilityName)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.facilityName }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.facilityLicense }}
                    </div>
                    <div v-if="data.item.facilityDba" class="font-small-2 text-muted">DBA: {{ data.item.facilityDba }}</div>
                  </div>
                </div>
              </template>
              <template #cell(allInventory)="data">
                <b-link @click="onInventoryCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
              <template #cell(newInventory)="data">
                <b-link @click="onInventoryCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <!-- Listing counts table Card -->
        <b-col lg="12">
          <b-card v-if="listingCounts" class="card-company-table" title="Listing Counts">
            <b-table :items="listingCounts" responsive :fields="listingCountsFields" class="mb-0">
              <!-- facility information -->
              <template #cell(facilityName)="data">
                <div class="d-flex align-items-center">
                  <div>
                    <div class="font-weight-bolder">
                      {{ data.item.facilityName }}
                    </div>
                    <div class="font-small-2 text-muted">
                      {{ data.item.facilityLicense }}
                    </div>
                    <div class="font-small-2 text-muted">DBA: {{ data.item.facilityDba }}</div>
                  </div>
                </div>
              </template>
              <template #cell(active)="data">
                <template v-if="data.value">
                  <feather-icon icon="CheckCircleIcon" class="rounded-circle bg-success p-1" variant="light" />
                </template>
                <template v-else>
                  <feather-icon icon="CheckCircleIcon" class="rounded-circle bg-danger p-1" variant="light" />
                </template>
              </template>
              <template #cell(total)="data">
                <b-link @click="onListingCountClicked(data)">
                  {{ data.value }}
                </b-link>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col md="6">
          <dashboard-carousel :data="newsList || []" header="News" image-width="924" image-height="480" />
        </b-col>
        <b-col md="6">
          <dashboard-carousel :data="blogsList || []" header="Blogs" image-width="924" image-height="480" />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import DashboardCarousel from "./DashboardCarousel.vue";
import useDashboard from "@/views/dashboard/useDashboard";
import Ripple from "vue-ripple-directive";
import { useRouter } from "@core/utils/utils";

export default {
  name: "Dashboard",
  components: {
    DashboardCarousel,
    Ripple
  },
  directives: {
    Ripple
  },
  setup() {
    const { newsList, blogsList, listingCounts, inventoryCounts, sampleCounts, getStoreLoading, onLoadDashboard, currentPage, currentSamplePage } = useDashboard();

    const { router } = useRouter();

    const listingCountsFields = [
      { key: "total", label: "Total" },
      { key: "orgName", label: "Org Name" },
      { key: "active", label: "Active" }
    ];

    const inventoryCountsFields = [
      { key: "allInventory", label: "All Inventory" },
      { key: "newInventory", label: "New Inventory" },
      { key: "facilityName", label: "Facility Name" },
      { key: "newFrom", label: "New From" }
    ];

    const sampleCountsFields = [
      { key: "facilityName", label: "Facility Name" },
      { key: "orgName", label: "Org Name" },
      { key: "requestedTotal", label: "Requested Total" },
      { key: "shippedTotal", label: "Shipped Total" },
      { key: "receivedTotal", label: "Received Total" },
      { key: "reviewedTotal", label: "Reviewed Total" }
    ];

    const onFindDashboardInfo = async () => await onLoadDashboard();

    const onInventoryCountClicked = (row) => {
      router.push({ name: "inventory", params: { inventoryRow: row.item } });
    };

    const onSampleCountClicked = (row) => {
      router.push({ name: "samples", params: { sampleRow: row.item } });
    };

    const onListingCountClicked = (row) => {
      router.push({ name: "listings", params: { listingRow: row.item } });
    };

    onFindDashboardInfo();

    return {
      newsList,
      blogsList,
      listingCounts,
      inventoryCounts,
      sampleCounts,
      getStoreLoading,
      listingCountsFields,
      inventoryCountsFields,
      sampleCountsFields,
      onLoadDashboard,
      onInventoryCountClicked,
      onSampleCountClicked,
      onListingCountClicked,
      currentPage,
      currentSamplePage
    };
  }
};
</script>

<style scoped></style>
